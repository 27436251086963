import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector, useStore } from 'react-redux'
import _ from 'lodash'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import { constants as co, IMAGE_TYPE, PICTURE_SIZE, UPLOAD_TYPE } from '../../Constants/constants'
import { getOne, put } from '../../helper/baseFetch'
import { getScDisabled } from '../../helper/util'
import { setTicketType, setTicketTypeValue } from '../../actions/ticketTypeAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { TempAutoSelectInput } from '../../Component/Input/TempAutoSelectInput'
import { TempCustomCheckBox } from '../../Component/Custom/TempCustomCheckBox'
import { DialogWrapper } from '../../Component/DialogWrapper'
import { QueueTemplateColorPicker } from '../QueueTemplate/QueueTemplateColorPicker'
import { UploadImage } from '../../Component/UploadImage/UploadImage'
import TicketTypeMapList from './TicketTypeMapList'

export const TicketTypeEdit = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const store = useStore()
  const history = useHistory()
  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(true)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const resource = 'TicketType'
  const savedTicketType = useSelector((state) => state.ticketType.ui.ticketType)
  const [ticketTypeInputList, setTicketTypeInputList] = useState(_.cloneDeep(savedTicketType))

  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: co.TICKET_TYPE.INFO,
    },
    // {
    //   label: { resource: resource, key: 'ticketTypeMapList' },
    //   tabIndex: co.TICKET_TYPE.TICKET_TYPE_MAP_LIST,
    // },
  ]

  const fields = [
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'ticketType' },
      value: savedTicketType.ticketType,
      name: 'ticketType',
      isEndOfLine: false,
      required: true,
      disabled: true,
      variant: co.STYLE.VARIANT.STANDARD,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'status' },
      value: savedTicketType.status,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.TICKET_TYPE.STATUS,
      name: 'status',
      isEndOfLine: true,
      disableClearable: true,
      required: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'enName' },
      value: savedTicketType.enName,
      name: 'enName',
      custom: true,
      customName: { key: 'nameCustomize' },
      customValue: savedTicketType.scNameDisable,
      isEndOfLine: false,
      required: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'tcName' },
      value: savedTicketType.tcName,
      name: 'tcName',
      translateName: savedTicketType.scNameDisable ? ['scName'] : null,
      inputList: ticketTypeInputList,
      setInputList: setTicketTypeInputList,
      isEndOfLine: false,
      required: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'scName' },
      value: savedTicketType.scName,
      name: 'scName',
      inputList: ticketTypeInputList,
      setInputList: setTicketTypeInputList,
      isEndOfLine: true,
      disabled: savedTicketType.scNameDisable,
      required: true,
    },
    {
      label: { resource: resource, key: 'foregroundColor' },
      value: savedTicketType.foregroundColor,
      name: 'foregroundColor',
      isEndOfLine: false,
      disabled: savedTicketType.foregroundColor,
      freeSolo: true,
      endAdornmentIconButton: (
        <QueueTemplateColorPicker color={savedTicketType.foregroundColor} setColor={(color) => onChangeInput('foregroundColor', color)} />
      ),
      onClickEndAdornmentIconButton: () => {},
      freeSoloUppercase: true,
      isColorFieldSpecialCase: true,
      clear: true,
      required: true,
    },
    {
      label: { resource: resource, key: 'backgroundColor' },
      value: savedTicketType.backgroundColor,
      name: 'backgroundColor',
      isEndOfLine: true,
      disabled: savedTicketType.backgroundColor,
      freeSolo: true,
      endAdornmentIconButton: (
        <QueueTemplateColorPicker color={savedTicketType.backgroundColor} setColor={(color) => onChangeInput('backgroundColor', color)} />
      ),
      onClickEndAdornmentIconButton: () => {},
      freeSoloUppercase: true,
      isColorFieldSpecialCase: true,
      clear: true,
      required: true,
    },
  ]

  const content = [
    {
      tab: (
        <>
          {fields.map((inputSetting, index) => {
            let content
            switch (inputSetting.type) {
              case co.TYPE.SELECT:
                content = <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
                break
              default:
                content = <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
            }
            return (
              <div className="edit-display-wrapper" key={index}>
                {inputSetting.custom && (
                  <TempCustomCheckBox
                    className="customize-btn"
                    value={inputSetting.customValue}
                    customName={inputSetting.customName}
                    onSelectCheckbox={onSelectCheckbox}
                  />
                )}
                {content}
                {inputSetting.isEndOfLine && <div></div>}
              </div>
            )
          })}
          <div className="image">
            <UploadImage
              type={IMAGE_TYPE.RESTAURANT_IMAGE}
              title={{ resource: resource, key: 'mobileImage' }}
              name="mobileImage"
              saveImage={(e) => {
                dispatch(setTicketTypeValue('mobileImage', savedTicketType, '', e.picture))
              }}
              picture={savedTicketType.mobileImage}
              uploadType={UPLOAD_TYPE.FORMDATA}
              uploadImagePath={co.PATH.SHOP.UPLOAD_RESTAURANT_MEDIA}
              size={PICTURE_SIZE.MID}
              allowedFileExtensionList={['image/jpeg', 'image/jpg']}
              required={true}
            />
          </div>
          <div className="image">
            <UploadImage
              type={IMAGE_TYPE.RESTAURANT_IMAGE}
              title={{ resource: resource, key: 'kioskImage' }}
              name="kioskImage"
              saveImage={(e) => {
                dispatch(setTicketTypeValue('kioskImage', savedTicketType, '', e.picture))
              }}
              picture={savedTicketType.kioskImage}
              uploadType={UPLOAD_TYPE.FORMDATA}
              uploadImagePath={co.PATH.SHOP.UPLOAD_RESTAURANT_MEDIA}
              size={PICTURE_SIZE.MID}
              allowedFileExtensionList={['image/jpeg', 'image/jpg']}
              required={true}
            />
          </div>
        </>
      ),
      btn: true,
    },
    // {
    //   tab: <TicketTypeMapList />,
    //   btn: false,
    // },
  ]

  const bottomBtns = [
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'save' },
      disabled:
        Object.values(savedTicketType).filter((item) => {
          return item === '' || item === null
        }).length > 0
          ? true
          : false,
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  function onSelectCheckbox(value) {
    dispatch(setTicketTypeValue('scNameDisable', savedTicketType, '', value))
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + co.PATH.SHOP.QUEUE.TICKET_TYPE)
  }

  function onChangeInput(name, e) {
    if (savedTicketType.scNameDisable === true && name === 'tcName') {
      dispatch(setTicketTypeValue('CHANGE_TC_NAME_AND_SC_NAME', savedTicketType, '', e))
    } else {
      dispatch(setTicketTypeValue(name, savedTicketType, '', e))
    }
  }

  function onClickSave() {
    put(
      co.PATH.SHOP.QUEUE.TICKET_TYPE + '/' + params.id,
      savedTicketType,
      () => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: 'editSuccess' },
          dialogActions: [
            { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
          ],
        })
        setClear(false)
      },
      undefined,
      store
    )
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(
        co.PATH.SHOP.QUEUE.TICKET_TYPE + '/' + params.id,
        undefined,
        (payload) => {
          payload.scNameDisable = getScDisabled(payload.tcName, payload.scName)

          dispatch(setTicketType(_.cloneDeep(payload)))
          setTicketTypeInputList(_.cloneDeep(payload))
          setReady(true)
          setClear(false)
        },
        undefined,
        store
      )
    }
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   console.log('savedTicketType', savedTicketType)
  // }, [savedTicketType])

  return (
    ready && (
      <>
        <EditCreateDisplay
          code={savedTicketType && savedTicketType.ticketTypeId ? savedTicketType.ticketTypeId : ''}
          resource={resource}
          tabs={tabs}
          content={content}
          onChangeInput={onChangeInput}
          bottomBtns={bottomBtns}
          display="edit"
        />
        {dialog && dialog.open && <DialogWrapper dialogInfo={dialog} />}
      </>
    )
  )
}
